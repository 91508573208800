// Built-ins
import React from "react";

// Modules / Functions
import axios from "axios";
import { Card } from "react-bootstrap";
import { Grid } from "semantic-ui-react";
import ReactCountryFlag from "react-country-flag";
import Swal from "sweetalert2";

// Custom Made Components
import ChangeName from "../../../modals/change_name";
import ChangeAddress from "../../../modals/change_address";
import ChangeEmail from "../../../modals/change_email";
import ChangeTel from "../../../modals/change_tel";
import DeleteObjectModal from "../../../modals/delete_object";
import ChangeEnabled from "../../../modals/change_enabled";
import ChangeLanguage from "../../../modals/guides/change_languages";
import EditPaymentDetails from "../../../modals/edit_payment_details";
import Notes from "../../../core/notes/notes";
import ChangeAddress2 from "../../../modals/change_address2";
import ChangePostal from "../../../modals/change_postal";
import ChangeRegion from "../../../modals/change_region";

// Icons / Images
import { BsInfoSquare } from "react-icons/bs";
import { MdCreditCard } from "react-icons/md";
import { FaFlag, FaHashtag, FaCheck } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaMinus } from "react-icons/fa";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { GiConvergenceTarget } from "react-icons/gi";
import { BsMailbox } from "react-icons/bs";
import { IoMdBusiness } from "react-icons/io";

// Global Variables
import {
  headers,
  loader,
  pageHeader,
  forbidden,
  restrictedUsers,
  iconStyle,
  languagePerCountry,
} from "../../../global_vars";

// Variables
window.Swal = Swal;

let overviewIconStyle = { color: "#F3702D", marginRight: "0.5em" };

const VIEW_GUIDE = "https://groupplan.gr/api/data_management/guide/";

function getGuideId() {
  return window.location.pathname.split("/")[3];
}

class GuideOverView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guide: {
        notes: {},
      },
      is_loaded: false,
      forbidden: false,
      show_tel2: false,
      show_address2: false,
    };
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    if (restrictedUsers().includes(localStorage.getItem("user"))) {
      this.setState({
        forbidden: true,
      });
    }
    headers["Authorization"] = "Token " + localStorage.getItem("userToken");
    axios
      .get(VIEW_GUIDE + getGuideId(), {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          guide: res.data.guide,
          notes: res.data.guide.notes,
          is_loaded: true,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          this.setState({
            forbidden: true,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An unknown error has occured.",
          });
        }
      });
  }

  update_state = (update_state) => {
    this.setState({ guide: update_state });
  };

  refresh = () => {
    headers["Authorization"] = "Token " + localStorage.getItem("userToken");
    axios
      .get(VIEW_GUIDE + getGuideId(), {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          guide: res.data.guide,
          is_loaded: true,
        });
      });
  };

  update_coach_op = (guide) => {
    this.setState({ guide: guide });
  };

  update_notes = (notes) => {
    var guide = { ...this.state.guide };
    guide.notes = notes;
    this.setState({
      guide: guide,
    });
  };

  render() {
    return (
      <>
        <div className="mainContainer">
          {pageHeader("guide_overview", this.state.guide.name)}
          {this.state.forbidden ? (
            <>{forbidden("Local Guide Overview")}</>
          ) : this.state.is_loaded ? (
            <>
              <Grid stackable columns={2} divided>
                <Grid.Row style={{ marginLeft: 2 }}>
                  <Grid.Column>
                    <Card>
                      <Card.Header>
                        <BsInfoSquare
                          style={{
                            color: "#F3702D",
                            fontSize: "1.5em",
                            marginRight: "0.5em",
                          }}
                        />
                        Local Guide Information
                      </Card.Header>
                      <Card.Body>
                        <div className={"info_descr"}>
                          <FaHashtag style={overviewIconStyle} /> Name
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.name
                            ? this.state.guide.name
                            : "N/A"}
                        </div>
                        <ChangeName
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                        />

                      <div className={"info_descr"}> <IoMdBusiness style={overviewIconStyle} /> Company </div>
                      <div className={"info_span"}>
                        {this.state.guide.payment_details
                          ? this.state.guide.payment_details.company === "" ||
                            this.state.guide.payment_details.company === null
                            ? "N/A"
                            : 
                            <span style={{color: this.state.guide.payment_details.company === this.state.guide.name ? 'blue': ''}}>
                              {this.state.guide.payment_details.company}
                            </span>
                          : "N/A"}
                      </div>

                        <div className={"info_descr"}>
                          <FaAddressCard style={overviewIconStyle} /> Address
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.contact.address
                            ? this.state.guide.contact.address
                            : "N/A"}
                        </div>
                        <ChangeAddress
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                          address={
                            this.state.guide.contact.address
                              ? this.state.guide.contact.address
                              : "N/A"
                          }
                        />

                        {this.state.show_address2 ? (
                          <>
                            <div className={"info_descr"}>
                              <FaAddressCard style={overviewIconStyle} />
                              Address 2
                            </div>
                            <div className={"info_span"}>
                              {this.state.guide.contact.address2
                                ? this.state.guide.contact.address2
                                : "N/A"}
                            </div>
                            <FaMinus
                              className="minus-icon"
                              title="Hide address 2"
                              style={{ marginLeft: 20 }}
                              onClick={() =>
                                this.setState({ show_address2: false })
                              }
                            />
                            <ChangeAddress2
                              object_id={this.state.guide.id}
                              object_name={this.state.guide.name}
                              object_type={"Guide"}
                              update_state={this.update_state}
                              address={
                                this.state.guide.contact.address2
                                  ? this.state.guide.contact.address2
                                  : "N/A"
                              }
                            />
                          </>
                        ) : (
                          <>
                            <AiOutlinePlusSquare
                              className="plus-icon"
                              title="Show Address 2"
                              style={{ marginLeft: 20 }}
                              onClick={() =>
                                this.setState({ show_address2: true })
                              }
                            />
                          </>
                        )}

                        <div className={"info_descr"}>
                          <BsFillTelephoneFill style={overviewIconStyle} /> Tel
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.contact.tel
                            ? this.state.guide.contact.tel
                            : "N/A"}
                        </div>
                        <ChangeTel
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          tel_num={"tel"}
                          update_state={this.update_state}
                          telephone={
                            this.state.guide.contact.tel
                              ? this.state.guide.contact.tel
                              : "N/A"
                          }
                        />
                        {this.state.show_tel2 ? (
                          <>
                            <div className={"info_descr"}>
                              <BsFillTelephoneFill style={overviewIconStyle} />
                              Tel. 2
                            </div>
                            <div className={"info_span"}>
                              {this.state.guide.contact.tel2
                                ? this.state.guide.contact.tel2
                                : "N/A"}
                            </div>

                            <ChangeTel
                              object_id={this.state.guide.id}
                              object_name={this.state.guide.name}
                              object_type={"Guide"}
                              tel_num={"tel2"}
                              update_state={this.update_state}
                              telephone={
                                this.state.guide.contact.tel2
                                  ? this.state.guide.contact.tel2
                                  : "N/A"
                              }
                            />
                            <div className={"info_descr"}>
                              <BsFillTelephoneFill style={overviewIconStyle} />
                              Tel. 3
                            </div>
                            <div className={"info_span"}>
                              {this.state.guide.contact.tel3
                                ? this.state.guide.contact.tel3
                                : "N/A"}
                            </div>

                            <FaMinus
                              className="minus-icon"
                              title="Hide address 2"
                              style={{ marginLeft: 20 }}
                              onClick={() =>
                                this.setState({ show_tel2: false })
                              }
                            />

                            <ChangeTel
                              object_id={this.state.guide.id}
                              object_name={this.state.guide.name}
                              object_type={"Guide"}
                              tel_num={"tel3"}
                              update_state={this.update_state}
                              telephone={
                                this.state.guide.contact.tel3
                                  ? this.state.guide.contact.tel3
                                  : "N/A"
                              }
                            />
                          </>
                        ) : (
                          <>
                            <AiOutlinePlusSquare
                              className="plus-icon"
                              title="Show Tel 2"
                              style={{ marginLeft: 20 }}
                              onClick={() => this.setState({ show_tel2: true })}
                            />
                          </>
                        )}

                        <div className={"info_descr"}>
                          <GiConvergenceTarget style={overviewIconStyle} />
                          Region
                        </div>

                        <div className={"info_span"}>
                          {this.state.guide.region ? this.state.guide.region : 'N/A'}
                        </div>

                        <ChangeRegion
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                        />

                        <div className={"info_descr"}>
                          <BsMailbox style={overviewIconStyle} />
                          Postal / Zip code
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.contact.postal
                            ? this.state.guide.contact.postal
                            : "N/A"}
                        </div>
                        <ChangePostal
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                          postal={
                            this.state.guide.contact.postal
                              ? this.state.guide.contact.postal
                              : "N/A"
                          }
                        />
                        <div className={"info_descr"}>
                          <MdAlternateEmail style={overviewIconStyle} />
                          Email
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.contact.email
                            ? this.state.guide.contact.email
                            : "N/A"}
                        </div>
                        <ChangeEmail
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                          email={
                            this.state.guide.contact.email
                              ? this.state.guide.contact.email
                              : ""
                          }
                        />
                        <div className={"info_descr"}>
                          <FaFlag style={overviewIconStyle} /> Languages
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.flags.length > 0
                            ? this.state.guide.flags.map((e) => (
                                <>
                                  <ReactCountryFlag
                                    countryCode={e.code}
                                    svg
                                    style={{
                                      width: "1.5em",
                                      height: "1.5em",
                                      marginRight: 10,
                                    }}
                                    title={[e.name] + ' - ' + languagePerCountry[e.name]}
                                  />
                                </>
                              ))
                            : "N/A"}
                        </div>
                        <ChangeLanguage
                          guide_id={this.state.guide.id}
                          name={this.state.guide.name}
                          update_state={this.update_state}
                        />
                        <div className={"info_descr"}>
                          {this.state.guide.enabled ? (
                            <FaCheck style={overviewIconStyle} />
                          ) : (
                            <ImCross style={overviewIconStyle} />
                          )}
                          Enabled
                        </div>

                        <div className={"info_span"}>
                          {this.state.guide.enabled ? "Enabled" : "Disabled"}
                        </div>

                        <ChangeEnabled
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                        />
                      </Card.Body>
                      <Card.Footer>
                        <DeleteObjectModal
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                        />
                      </Card.Footer>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card>
                      <Card.Header>
                        <MdCreditCard style={iconStyle} />
                        Payment Details
                      </Card.Header>
                      <Card.Body>
                        <div className={"info_descr"}> Company </div>
                        <div className={"info_span"}>
                          {this.state.guide.payment_details
                            ? this.state.guide.payment_details.company === "" ||
                              this.state.guide.payment_details.company === null
                              ? "N/A"
                              : this.state.guide.payment_details.company
                            : "N/A"}
                        </div>
                        <div className={"info_descr"}> Currency </div>
                        <div className={"info_span"}>
                          {this.state.guide.payment_details
                            ? this.state.guide.payment_details.currency ===
                                "" ||
                              this.state.guide.payment_details.currency === null
                              ? "N/A"
                              : this.state.guide.payment_details.currency
                            : "N/A"}
                        </div>
                        <div className={"info_descr"}>
                          {this.state.guide.payment_details.currency === "GBP"
                            ? "Account Number"
                            : "IBAN"}
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.payment_details
                            ? this.state.guide.payment_details.iban === "" ||
                              this.state.guide.payment_details.iban === null
                              ? "N/A"
                              : this.state.guide.payment_details.iban
                            : "N/A"}
                        </div>
                        <div className={"info_descr"}>
                          {this.state.guide.payment_details.currency === "GBP"
                            ? "Sort Code"
                            : "Swift"}
                        </div>
                        <div className={"info_span"}>
                          {this.state.guide.payment_details
                            ? this.state.guide.payment_details.swift === "" ||
                              this.state.guide.payment_details.swift === null
                              ? "N/A"
                              : this.state.guide.payment_details.swift
                            : "N/A"}
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <EditPaymentDetails
                          object_id={this.state.guide.id}
                          object_name={this.state.guide.name}
                          object_type={"Guide"}
                          update_state={this.update_state}
                          payment_details={this.state.guide.payment_details}
                        />
                      </Card.Footer>
                    </Card>
                    <Notes
                      update_notes={this.update_notes}
                      object_id={this.state.guide.id}
                      object_name={this.state.guide.name}
                      object_type={"Guide"}
                      update_state={this.update_state}
                      notes={this.state.guide.notes}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          ) : (
            loader()
          )}
        </div>
      </>
    );
  }
}

export default GuideOverView;
